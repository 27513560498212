import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import logo from "../images/logo.svg"
import icon_sun from "../images/icon-sun.svg"
import icon_dark from "../images/icon-dark.svg"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SunCalc from "suncalc"
import useWindowDimensions from "../helpers/utils"

import "../styl/app.styl"

const getTime = () => new Date().toLocaleTimeString("en-CA", {
  timeStyle: "short",
  timeZone: "America/New_York",
  hour12: false,
})

export default function Layout({ children, transitionStatus }, props) {
  const data = useStaticQuery(
    graphql`
      query FooterInfoQuery2 {
        contentfulFooterInfo {
          facebook
          instagram
          linkedin
          officeAddress {
            content {
              content {
                value
              }
            }
          }
          officeAddressTitle
          officeEmail
          officeEmailTitle
          officePhoneNumber
          officePhoneNumberTitle
          workTitle
          workUrlCopy
          footerTitle
          marquee1
          marquee2
          marquee3
        }
      }
    `
  )
  const {
    facebook,
    instagram,
    linkedin,
    officeAddress,
    officeEmail,
    officeAddressTitle,
    officeEmailTitle,
    officePhoneNumber,
    officePhoneNumberTitle,
    workTitle,
    workUrlCopy,
    footerTitle,
    marquee1,
    marquee2,
    marquee3,
  } = data.contentfulFooterInfo

  const [darkMode, setDarkMode] = useState(null)
  const [headerTransparent, setHeaderTransparent] = useState(false)
  const [scrollPassFooter, setScrollPassFooter] = useState(false)
  const [atWorkPage, setAtWorkPage] = useState(false)
  const [currentTime, setCurrentTime] = useState("")
  const [timeInitialized, setTimeInitialized] = useState(false)
  const myRef = useRef(null)
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const isBrowser = typeof window !== `undefined`
  const mblMenu = isBrowser ? document.getElementById("mbl-menu") : null
  const nav = isBrowser ? document.getElementById("nav") : null
  const dimmedOverlay = isBrowser
    ? document.getElementById("dimmedOverlay")
    : null
  const [scroll, setScroll] = useState(true)
  const { width } = useWindowDimensions()

  // TORONTO CURRENT SUNRISE TIME
  const sunriseTime = SunCalc.getTimes(
    new Date(),
    43.653225,
    -79.383186
  ).sunrise.toLocaleTimeString("en-CA", {
    timeStyle: "short",
    timeZone: "America/New_York",
    hour12: false,
  })

  // TORONTO CURRENT NIGHT TIME
  const nightTime = SunCalc.getTimes(
    new Date(),
    43.653225,
    -79.383186
  ).night.toLocaleTimeString("en-CA", {
    timeStyle: "short",
    timeZone: "America/New_York",
    hour12: false,
  })

  const hamburgerToggle = () => {
    mblMenu.classList.toggle("clicked")
    nav.classList.toggle("menu-display")
    dimmedOverlay.classList.toggle("dimmed")
    setScroll(!scroll)
  }

  const scrollToFooter = () => {
    mblMenu.classList.remove("clicked")
    nav.classList.remove("menu-display")
    scrollToRef(myRef)
    if (width < 1000) {
      setScroll(!scroll)
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const closeMblMenu = () => {
    mblMenu.classList.remove("clicked")
    nav.classList.remove("menu-display")
    dimmedOverlay.classList.remove("dimmed")
    if (width < 1000) {
      setScroll(!scroll)
    }
  }

  useLayoutEffect(() => {
    if (isBrowser) {
      const handleScroll = () => {
        let winScroll = window.pageYOffset
        let footerPos =
          document.body.clientHeight -
          document.getElementById("footer").getBoundingClientRect().height
        // SOCIAL MEDIA ICONS CHANGE COLOR
        winScroll + window.innerHeight > footerPos + 100
          ? setScrollPassFooter(true)
          : setScrollPassFooter(false)
        // HEADER BG CHANGE COLOR WHEN TOUCH FOOTER
        winScroll + 90 > footerPos
          ? setHeaderTransparent(true)
          : setHeaderTransparent(false)
      }

      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
    }
  })


  useEffect(() => {
    if (!timeInitialized) {
      setCurrentTime(getTime())
      setInterval(() => {
        setCurrentTime(getTime())
      }, 1000)
      setTimeInitialized(true)
    }
  })

  useEffect(() => {
    // console.log('useeffect began');
    // let time = new Date().toLocaleTimeString("en-CA", {
    //   timeStyle: "short",
    //   timeZone: "America/New_York",
    //   hour12: false,
    // })

    // // CLOCK FUNCTION
    // setInterval(() => {
    //   setCurrentTime(time)
    // }, 1000)

    const time = getTime()

    // DARK MODE
    let torontoCurrentTimeHour = parseInt(time.substring(0, 2))
    let torontoNightTimeHour = parseInt(nightTime.substring(0, 2))
    let torontoSunriseTimeHour = parseInt(sunriseTime.substring(0, 2))

    if ((torontoCurrentTimeHour >= torontoNightTimeHour) || (torontoSunriseTimeHour >= torontoCurrentTimeHour)) {
      setDarkMode(true)
    }
  }, [nightTime])

  useEffect(() => {
    if (children.props.children.props.children.key === "/work/") {
      setAtWorkPage(true)
    } else {
      setAtWorkPage(false)
    }
  }, [children.props.children.props.children.key])

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <html
          className={darkMode ? "dark-mode" : !scroll ? "no-scroll" : null}
        />
      </Helmet>
      <header className={headerTransparent ? "black" : null}>
        <div className="content-wrapper">
          <nav id="nav">
            <ul>
              <li>
                <AniLink
                  fade
                  to="/work/"
                  onClick={closeMblMenu}
                  activeClassName="active"
                >
                  Work
                </AniLink>
              </li>
              <li>
                <AniLink
                  fade
                  to="/services/"
                  onClick={closeMblMenu}
                  activeClassName="active"
                >
                  Services
                </AniLink>
              </li>
              <li>
                <AniLink
                  fade
                  to="/about/"
                  onClick={closeMblMenu}
                  activeClassName="active"
                >
                  About
                </AniLink>
              </li>
              <li>
                <div onClick={scrollToFooter}>Contact</div>
              </li>
            </ul>
            <div
              className={
                scrollPassFooter
                  ? "social-media-list dark-bg"
                  : "social-media-list"
              }
            >
              <a href={facebook} rel="noopener noreferrer" target="_blank">
                FB
              </a>
              <a href={instagram} rel="noopener noreferrer" target="_blank">
                IG
              </a>
              <a href={linkedin} rel="noopener noreferrer" target="_blank">
                LI
              </a>
            </div>
            <div className="time-mode">
              <div className="icon-sun">
                {darkMode || headerTransparent ? (
                  <img src={icon_dark} alt="" />
                ) : (
                  <img src={icon_sun} alt="" />
                )}
              </div>
              <div className="time">{currentTime} EST</div>
            </div>
          </nav>
          <div
            className="invisible-overlay"
            id="dimmedOverlay"
            onClick={hamburgerToggle}
          ></div>
          <div className="logo">
            <AniLink fade to="/">
              <img src={logo} alt="The Workhouse" />
            </AniLink>
          </div>
          <div className="mbl-menu" id="mbl-menu" onClick={hamburgerToggle}>
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <main>{children}</main>
      <footer ref={myRef} id="footer">
        <div className="content-wrapper">
          <h1>{footerTitle}</h1>
          <div className="footer-info-wrapper">
            <div className="footer-info">
              <div className="contact-info">
                <span className="all-caps">{officeEmailTitle}</span>
                <a href={`mailto:${officeEmail}`} className="hover-underline">
                  {officeEmail}
                </a>
              </div>
              <div className="contact-info">
                <span className="all-caps">{officePhoneNumberTitle}</span>
                <a
                  href={`tel:${officePhoneNumber}`}
                  className="hover-underline"
                >
                  {officePhoneNumber}
                </a>
              </div>
              <div className="contact-info">
                <span className="all-caps">{officeAddressTitle}</span>
                <div
                  className="address"
                  dangerouslySetInnerHTML={createMarkup(
                    officeAddress.content[0].content[0].value
                  )}
                ></div>
              </div>
            </div>
            <div className="footer-info">
              <div className="more-work all-caps">
                <div className="work-title">{workTitle}</div>
                {!atWorkPage ? (
                  <AniLink fade to="/work/">
                    {workUrlCopy}
                    <span>→</span>
                  </AniLink>
                ) : (
                  <div className="scrollTopLink" onClick={scrollToTop}>
                    {workUrlCopy}
                    <span>→</span>
                  </div>
                )}
              </div>
              <div className="countries">
                <div className="c1">
                  <span>{marquee1}</span>
                  <span>{marquee1}</span>
                </div>
                <div className="c2">
                  <span>{marquee2}</span>
                  <span>{marquee2}</span>
                </div>
                <div className="c3">
                  <span>{marquee3}</span>
                  <span>{marquee3}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            © {new Date().getFullYear()} The Workhouse{" "}
            <AniLink fade to="/careers/" className="hover-underline">
              Careers
            </AniLink>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export const query = graphql`
  query FooterInfoQuery {
    contentfulFooterInfo {
      facebook
      instagram
      linkedin
      officeAddress {
        officeAddress
      }
      officeAddressTitle
      officeEmail
      officeEmailTitle
      officePhoneNumber
      officePhoneNumberTitle
      workTitle
      workUrlCopy
      footerTitle
    }
  }
`

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-study-short-form-js": () => import("C:\\gatsby\\src\\templates\\case-study-short-form.js" /* webpackChunkName: "component---src-templates-case-study-short-form-js" */),
  "component---src-templates-case-study-long-form-js": () => import("C:\\gatsby\\src\\templates\\case-study-long-form.js" /* webpackChunkName: "component---src-templates-case-study-long-form-js" */),
  "component---src-pages-404-js": () => import("C:\\gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\gatsby\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("C:\\gatsby\\src\\pages\\careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("C:\\gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("C:\\gatsby\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("C:\\gatsby\\src\\pages\\work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

